import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { getModule } from 'external/firebase/controllers/db';

import useAuth from 'hooks/useAuth';
import Login from 'pages/auth/Login/Login';
import { PATH_AUTH } from 'routes/paths';

const AuthGuard: React.FunctionComponent = ({ children }) => {
    const { isAuthenticated, isVerified } = useAuth();
    const { pathname } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
    const { module: moduleId } = useParams();
    const [isPublic, setPublic] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const fetchModule = async (module: string) => {
        setLoading(true);

        const response = await getModule(module);
        if (response && 'public' in response && response.public === true) {
            setPublic(true);
        } else {
            setPublic(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (moduleId) {
            fetchModule(moduleId);
        }
    }, [moduleId]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isPublic && moduleId) {
        return <>{children}</>;
    }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Login />;
    }

    if (!isVerified) {
        return <Navigate to={PATH_AUTH.verify} replace />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
};

export default AuthGuard;
