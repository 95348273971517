const en = {
    all: 'All',
    allowed: 'Allowed',
    already_have_account: 'Already have an account?',
    and: 'and',
    back: 'back',
    by_registering_agree: 'By registering, I agree to CodeAcademy Online',
    change_password: 'change password',
    code: 'Code',
    code_required: 'Code is required',
    confirm_new_password: 'Confirm New Password',
    content: 'Content',
    dashboard: 'Dashboard',
    didnt_receive_email: 'Didn’t receive an email?',
    dont_have_account: `Don’t have an account?`,
    edit: 'Edit',
    email_address: 'Email address',
    email_address_must_be_from_codeacademy_domain: 'Must be a valid CodeAcademy email',
    email_address_must_be_valid: 'Email must be a valid email address',
    email_address_required: 'Email address is required',
    enter_new_password_subtitle: 'Please enter your new password that will be associated with your account.',
    enter_new_password_title: 'Enter your new password',
    error_fetching_lessons: 'Something went wrong fetching the lessons.',
    error_fetching_modules: 'Something went wrong fetching the modules.',
    error_fetching_topic_content: 'Something went wrong fetching the topic content.',
    error_fetching_topics: 'Something went wrong fetching the topics.',
    finish: 'Finish',
    first_name: 'First name',
    first_name_required: 'First name is required',
    forgot_password: 'Forgot password',
    forgot_your_password_details:
        'Please enter the email address associated with your account and we will email you a link to reset your password.',
    forgot_your_password_title: 'Forgot your password?',
    general: 'general',
    go_to_home: 'Go to Home',
    group: 'Group',
    group_content: 'Group Content',
    groups: 'Groups',
    groups_content: "group's content",
    groups_content_updates: `Group's content updated`,
    home: 'Home',
    incorrect_or_tampered_link: 'Incorrect or tampered link',
    internal_server_error: 'Internal Server Error',
    internal_server_error_description:
        'There was an error. Please try again later or contact your system administrator.',
    last_name: 'Last name',
    last_name_required: 'Last name is required',
    latest: 'Latest',
    lesson: 'Lesson',
    lessons: 'Lessons',
    link_to_login_text: 'Login',
    link_to_registration_text: 'Get started',
    login: `Login`,
    login_form_subtitle: `Enter your credentials below.`,
    login_form_title: 'Sign in to CodeAcademy Online',
    login_greeting_title: 'Welcome Back!',
    login_illustration: 'login illustration',
    login_with_google: `Login with Google`,
    logout: `Logout`,
    module_code: 'Module code',
    module_illustration: 'module illustration',
    modules: 'Modules',
    name: 'Name',
    new_password: 'New Password',
    new_password_helper_text: 'Password must be minimum 8+',
    new_password_required: 'New password is required',
    next: 'Next',
    no_lessons_found: 'No lessons found',
    no_modules_found: 'No modules found',
    no_results_for: 'No results found for',
    no_results_helper_text: 'Try checking for typos or using complete words.',
    no_topics_found: 'No topics found',
    not_found: 'Not found',
    notifications: 'notifications',
    old_password: 'Old Password',
    old_password_required: 'Old Password is required',
    open_lessons_helper_text: 'Empty value means module is not visible',
    opened_lessons: 'Opened lessons',
    page_not_found: 'Page Not Found',
    page_not_found_description:
        'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
    password: 'Password',
    password_must_be_at_least_8_characters: 'Password must be at least 8 characters',
    password_must_match: 'Passwords must match',
    password_required: 'Password is required',
    please_check_your_email: 'Please check your email.',
    please_try_again_or_contact_admin:
        'Please try again later or contact your system administrator if the issue persists.',
    privacy_policy: 'Privacy Policy',
    redirecting: 'Redirecting',
    register: `Register`,
    register_illustration: 'register illustration',
    registration_form_subtitle: `Exclusively dedicated to the students of CodeAcademy and CodeAcademy College.`,
    registration_form_title: 'Join CodeAcademy Online',
    registration_greeting_title: `An online education platform to support our students`,
    remember_me: 'Remember me',
    confirm_email: 'Confirm your email address',
    resend_email: 'Resend email',
    reset_password: 'Reset Password',
    reset_password_greeting_title: 'Get back on track and continue learning',
    reset_password_illustration: 'reset password illustration',
    save_changes: 'Save Changes',
    search_groups: 'Search groups',
    sent_confirmation_email_to: 'We have sent a confirmation email to',
    set_password: 'Set Password',
    something_went_wrong: 'Something went wrong.',
    sorted_ascending: 'sorted ascending',
    sorted_descending: 'sorted descending',
    terms_of_service: 'Terms of Service',
    too_long: 'Too Long!',
    too_short: 'Too Short!',
    topic: 'Topic',
    topics: 'Topics',
    unable_to_logout: `Unable to logout!`,
    update_photo: 'Update photo',
    upload_photo: 'Upload photo',
    user: 'User',
    user_management: 'User Management',
    verify: 'Verify',
    verify_code_subtitle: 'Enter a 6 characters confirmation code that was shared with your group',
    verify_code_title: 'Please enter your group code',
    verify_email_and_code_illustration: 'verify email and code illustration',
    verify_email_subtitle_part_1: 'We have sent you a confirmation link to',
    verify_email_subtitle_part_2: 'please click that link to verify your email.',
    verify_email_title: 'Please check your email!',
    view_lesson: 'View lesson',
    view_module: 'View module',
    your_account: 'Settings',
    lastest_lesson: 'Latest Lesson',
    all_lessons: 'All Lessons',
    open_lessons: 'open lessons',
    settings: 'settings',
    language: 'language',
    language_preference: 'Language perference',
    language_preference_saved: 'Language preference updated successfully',
    language_required: 'Language preference is required',
    login_success: 'Logged in successfully',
    register_success: 'Registered successfully',
    user_update_success: 'User profile updated successfully',
    password_update_success: 'Password updated successfully',
    password_reset_success: 'Password reset successfully',
    password_reset_code_verified: 'Password reset code verified',
    'auth/email-already-in-use': 'The provided email is already in use by an existing user',
    'auth/expired-action-code': 'The link has expired',
    'auth/invalid-action-code': 'The link is tampered or was used before',
    'auth/invalid-email': 'The provided email is invalid',
    'auth/invalid-user-token': 'The user token is invalid',
    'auth/user-disabled': 'The user is disabled',
    'auth/user-not-found': 'The user is not found. If you are a new user, please register first',
    'auth/user-token-expired': 'The user token is expired',
    'auth/weak-password': 'Password is too weak',
    'auth/wrong-password': 'Incorrect credentials',
    'auth/invalid-group-code': 'Invalid group code',
    'db/no-document': 'No record(s) found',
    last_updated: 'Last Updated',
    by: 'by',
    phone_number_is_not_valid: 'Phone number is not valid',
    phone_number: 'Phone number',
    save: 'Save',
    fill_data: 'Fill the data bellow',
};

export default en;
